.fade {
    animation-name: fade-in;
    animation-duration: 2s;
}
.age-state {
    opacity: 100;
}
@keyframes fade-in {
    from {opacity: 0; }
    to { opacity: 100; }

}

.textCenter {
    text-align: center;
  }

.sidebar {
    top: 0;
    flex: 1 0 auto;
    width: 19%;
    height: 100%;
    display: flex;
    outline: 0;
    z-index: 1200;
    position: fixed;
    overflow-y: auto;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .sidebarLogo {
    height: 50px;
    font-weight: 700;
    font-size: 20px;
    margin-top: 23px;
  }

  .headbar {
    top: 0;
    left: auto;
    right: 0;
    /* border-bottom: 5px solid#4169E1; */
    position: fixed;
    color: #484848;
    background-color: #ffffff;
    width: calc(100% - 19%);
    margin-left: 240px;
    box-shadow: -4px 7px 5px -5px rgba(0,0,0,0.46);  display: flex;
    z-index: 1100;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-direction: column;
  }
  
  .menuLink {
      height: 53px;
      background-color: #ffffff;
      margin-top: 5%;
      margin-bottom: 5%;
      display: flex;
      position: relative;
      align-items: center;
      font-size: 15px;
      padding-left: 12%;
      cursor: pointer;
  }
  
  ul {
    padding-left: 0px;
  }
  .menuActiveLink {
    background-color: #e6f7ff !important;
    border-left: 5px solid #1890ff;
  }

  .flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .nameText {
    font-size: large;
    font-weight: 800;
    margin-left: 5px;
    margin-right: 10px;
    margin-bottom: 0px;
  }
  .verticalAlign {
    display: flex;
    position: relative;
    align-items: center;
    height: 73px;
    padding-left: 5%;
  }

  img {
    width: 100%;
    height: 100%;
  }
  .MenuIcon {
    margin-right: 20px;
}

.MuiDivider-root {
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.12);
}

.logoBottom {
    position: absolute;
    bottom: 0;
}

.MainFormLayout {
    margin-top:  7%;
    margin-left: 25%;
  }
  
  .formLayoutBox {
    height: 100%;
    background: white;
    min-height: 500px;
    width: 65%;
    margin-left: 15%;
    padding: 25px;
    padding-left: 40px;
    padding-top: 50px;
    border-radius: 12px;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 10%);
  }
  
  .inputDate {
    height: 53px;
    cursor: pointer;
    width: 500px !important;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .submitButton {
    color: #fff;
    width: 184px;
    height: 42px;
    margin-right: 20px;
    border-radius: 4px;
    padding: 7px;
    cursor: pointer;
    border: none;
    padding-bottom: 10px;
    background-color: #1890ff;
    padding-top: 9px !important;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 14px;
    cursor: pointer;
  }

  .flexColum {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
  }

  .form-group {
    text-align: center;
    align-items: center;
    margin-bottom: 10% !important;
}


@media only screen and (max-width: 768px) {

  .sidebar {
    display: none;
  }
  .MainFormLayout {
    margin-left: 0% !important;
    
  }
  .formLayoutBox {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .headbar {
    width: 100% !important;
  }
  .inputDate {
    width: 100% !important;
  }
}